import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Parallax } from "react-parallax";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageDiv: {
      height: "700px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    textTitle: {
      color: theme.palette.common.white,
      opacity: 1,
      fontSize: "60px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
    textContent: {
      color: theme.palette.common.white,
      opacity: 1,
      fontSize: 28,
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
      marginTop: theme.spacing(2),
      maxWidth: "60%",
      textAlign: "center",
    },
    warning: {
      backgroundColor: "rgba(252,186,3,0.3)",
      color: "#fcba03",
      borderColor: "#fcba03",
      border: "1px solid",
      padding: "8px",
      display: "flex",
      justifyContent: "center",
    },
  })
);

export const AboutUs = () => {
  const classes = useStyles();
  const aboutUsText = "A propos de nous";
  const aboutUsContent = `Originaires de la Sicile, moi et mon mari avons décidé d'ouvrir un restaurant italien 
    pour apporter certaines de nos spécialités en France. Nos recettes se sont transmises dans notre famille au fil 
    des générations.`;
  const aboutUsEnd = `Ma che ! vous propose de découvrir une cuisine typique et faite maison avec des prix 
    accessibles à tous.`;
  return (
    <div>
      {/* <div className={classes.warning}>
        Attention: Fermeture exceptionnelle Dimanche 18/12 et Lundi 19/12.
      </div> */}
      <div id="about">
        <Parallax bgImage={"marzamemi.webp"} strength={-200}>
          <div className={classes.imageDiv}>
            <Typography className={classes.textTitle}>{aboutUsText}</Typography>
            <Typography className={classes.textContent}>
              {aboutUsContent}
            </Typography>
            <Typography className={classes.textContent}>
              {aboutUsEnd}
            </Typography>
          </div>
        </Parallax>
      </div>
    </div>
  );
};
