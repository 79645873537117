export type MenuItem = {
  name: string;
  price: string;
  ingredients?: string;
};

export type MenuSection = {
  title: string;
  items?: MenuItem[];
  component?: JSX.Element;
};

export type MenuContent = MenuSection[];

export enum MenuSectionEnum {
  PIZZA = "Pizza",
  PASTA = "Pasta",
  PIATTI = "Piatti",
  TAVOLA_CALDA = "Tavola calda",
  DESSERTS = "Desserts",
  BIBITE = "Boissons",
  ENTREES = "Entrées",
  ALCOOL = "Boissons alcoolisées",
  MENU_MIDI = "Menus Midi",
}
