import { MenuMidi } from "./MenuMidi";
import { MenuContent, MenuSectionEnum } from "./types";

export const MENU_CONTENT: MenuContent = [
  {
    title: MenuSectionEnum.ENTREES,
    items: [
      {
        name: "Bruschetta ( 2 pcs )",
        price: "7.00€",
        ingredients:
          "pain grillé, tomates, olives noires, origan, huile d'olive",
      },
      {
        name: "Burratina",
        price: "7.00€",
        ingredients: "burrata",
      },
      {
        name: "Focaccia",
        price: "7.00€",
        ingredients: "huile, sel, poivre, origan",
      },
      {
        name: "Mini arancini ( 4 pcs )",
        price: "6.00€",
        ingredients:
          "boulette de riz panée farcie avec sauce bolognaise et mozzarella",
      },
      {
        name: "Parmigiana",
        price: "9.00€",
        ingredients:
          "gratin d'aubergines avec sauce tomate, mozzarella et jambon",
      },
    ],
  },
  {
    title: MenuSectionEnum.PIZZA,
    items: [
      {
        name: "Margherita",
        price: "9.50€",
        ingredients: "sauce tomate, mozzarella, origan, huile",
      },
      {
        name: "Pesto",
        price: "10.50€",
        ingredients: "sauce tomate, mozzarella, pesto",
      },
      {
        name: "Regina",
        price: "12.00€",
        ingredients: "sauce tomate, mozzarella, jambon, champignons",
      },
      {
        name: "Norma",
        price: "12.50€",
        ingredients:
          "sauce tomate, mozzarella, aubergines fries, ricotta salata",
      },
      {
        name: "Capricciosa",
        price: "12.50€",
        ingredients: "sauce tomate, mozzarella, champignons, jambon, oeuf dur",
      },
      {
        name: "4 Stagioni",
        price: "12.50€",
        ingredients:
          "sauce tomate, mozzarella, jambon, artichauts, olives noires",
      },
      {
        name: "Rucola",
        price: "14.00€",
        ingredients:
          "sauce tomate, mozzarella, bresaola, grana padano, roquette",
      },
      {
        name: "Montanara",
        price: "13.00€",
        ingredients:
          "sauce tomate, mozzarella, lardons, oignons, champignons, olives noires",
      },
      {
        name: "Coppa",
        price: "13.00€",
        ingredients:
          "sauce tomate, mozzarella, coppa, olives vertes, tomates cerise",
      },
      {
        name: "Siciliana",
        price: "13.50€",
        ingredients:
          "sauce tomate, mozzarella, copeaux de pecorino, anchois, olives noires, tomates cerise, origan",
      },
      {
        name: "4 Formaggi",
        price: "12.50€",
        ingredients:
          "sauce tomate, mozzarella, provolone dolce, grana padano, gorgonzola",
      },
      {
        name: "Thon",
        price: "13.00€",
        ingredients:
          "sauce tomate, mozzarella, thon, oignons, persil, olives vertes, tomates cerise",
      },
      {
        name: "Ma Che",
        price: "13.50€",
        ingredients:
          "sauce tomate, mozzarella, champignons, jambon, olives noires, artichauts, oeuf dur, oignons",
      },
      {
        name: "Végétarienne",
        price: "12.50€",
        ingredients:
          "sauce tomate, mozzarella, aubergines, poivrons, champignons, artichauts",
      },
      {
        name: "Pistacchio",
        price: "14.00€",
        ingredients: "crème fraîche, mozzarella, mortadelle, pistaches",
      },
      {
        name: "Burrata",
        price: "15.00€",
        ingredients:
          "sauce tomate, mozzarella, jambon, tomates cerise, burrata",
      },
    ],
  },
  {
    title: MenuSectionEnum.PASTA,
    items: [
      {
        name: "Linguine al pomodoro",
        price: "9.50€",
        ingredients: "sauce tomate, huile, basilic",
      },
      {
        name: "Linguine al ragù",
        price: "12.50€",
        ingredients: "sauce tomate, viande hachée, poivre, carottes, oignons",
      },
      {
        name: "Fusilli alla boscaiola",
        price: "12.50€",
        ingredients: "crème fraîche, jambon, champignons, oignons",
      },
      {
        name: "Linguine alla norma",
        price: "12.50€",
        ingredients:
          "sauce tomate, ail, huile, basilic, aubergines fries, ricotta salata",
      },
      {
        name: "Linguine aux palourdes",
        price: "16.00€",
        ingredients:
          "palourdes, huile, touche de sauce tomate, tomates cerise, persil, ail",
      },
      {
        name: "Fusilli al tonno",
        price: "12.50€",
        ingredients: "oignons, thon, persil, huile, tomates cerise",
      },
      {
        name: "Linguine dello chef",
        price: "15.00€",
        ingredients: "pesto de basilic, tomates cerises, pecorino, burrata",
      },
      {
        name: "Linguine aglio e olio",
        price: "9.00€",
        ingredients:
          "ail, huile, persil, touche de piment, touche de sauce tomate",
      },
      {
        name: "Penne al pistacchio",
        price: "14.00€",
        ingredients: "crème fraîche, oignons rouges, lardons, pistaches",
      },
      {
        name: "Penne alla carbonara",
        price: "12.50€",
        ingredients: " lardons, jaune d'oeuf, grana padano, poivre",
      },
      {
        name: "Linguine à l'espadon",
        price: "17.00€",
        ingredients:
          "espadon, oignons, câpres, touche de sauce tomate, raisins secs",
      },
      {
        name: "Tortellini agli spinaci",
        price: "16.00€",
        ingredients:
          "tortellini farcis au fromage frais et épinards, sauce d'épinards avec crème fraîche, oignons, poivre et noix",
      },
      {
        name: "Gnocchi al pollo",
        price: "15.00€",
        ingredients:
          "gratin de gnocchis au poulet, avec crême fraîche et grana padano",
      },
      {
        name: "Penne al tartufo",
        price: "16.00€",
        ingredients: "crême fraîche à la truffe et champignons de Paris",
      },
    ],
  },
  {
    title: MenuSectionEnum.PIATTI,
    items: [
      {
        name: "Espadon mariné",
        price: "16.00€",
        ingredients:
          "Tranche d'espadon mariné avec huile, citron, sel et persil, acoompagné de sa salade verte",
      },
    ],
  },
  {
    title: MenuSectionEnum.TAVOLA_CALDA,
    items: [
      {
        name: "Arancino",
        price: "6.00€",
        ingredients:
          "boulette de riz panée farcie avec sauce bolognaise et mozzarella",
      },
      {
        name: "Pizza fritta - Prosciutto",
        price: "7.00€",
        ingredients: "calzone frit avec mozzarella, jambon",
      },
      {
        name: "Pizza fritta - Cipolla",
        price: "7.00€",
        ingredients: "calzone frit avec mozzarella, oignons",
      },
      {
        name: "Pizza fritta - Pomodoro",
        price: "7.50€",
        ingredients: "calzone frit avec mozzarella, jambon, sauce tomate",
      },
      {
        name: "Pizza fritta - Acciughe",
        price: "7.50€",
        ingredients: "calzone frit avec mozzarella, oignons, anchois",
      },
      {
        name: "Pizza fritta - Pecorino",
        price: "7.50€",
        ingredients: "calzone frit avec mozzarella, sauce tomate, pecorino",
      },
      {
        name: "Pizza fritta - Norma",
        price: "8.00€",
        ingredients:
          "calzone frit avec mozzarella, sauce tomate, aubergines, ricotta salée",
      },
    ],
  },
  {
    title: MenuSectionEnum.DESSERTS,
    items: [
      {
        name: "Cannoli siciliani",
        price: "6.00€ Grand / 3.00€ Petit",
        ingredients:
          "cannoli (gâteau sicilien), ricotta, sucre, cannelle, pépites de chocolat, poudre de pistache",
      },
      {
        name: "Tiramisu café",
        price: "5.00€",
        ingredients: "mascarpone, oeufs, sucre, biscuits, cacao en poudre",
      },
      {
        name: "Glaces 1 / 2 / 3 boules",
        price: "3.5 / 5 / 6.5€",
      },
    ],
  },
  {
    title: MenuSectionEnum.BIBITE,
    items: [
      {
        name: "Evian 0.5L",
        price: "3.50€",
      },
      {
        name: "Acqua St Pellegrino 0.5L",
        price: "4.00€",
      },
      {
        name: "Coca cola",
        price: "4.00€",
      },
      {
        name: "Fanta",
        price: "4.00€",
      },
      {
        name: "Chinotto",
        price: "4.50€",
      },
      {
        name: "Gassosa",
        price: "4.50€",
      },
      {
        name: "Limonata",
        price: "4.50€",
      },
      {
        name: "Arancia Rossa",
        price: "4.50€",
      },
      {
        name: "Mandarino Verde",
        price: "4.50€",
      },
      {
        name: "The glacé pêche",
        price: "4.50€",
      },
      {
        name: "Café Expresso",
        price: "1.50€",
      },
    ],
  },
  {
    title: MenuSectionEnum.ALCOOL,
    items: [
      {
        name: "Lambrusco blanc",
        price: "5€ verre / 20€ bouteille",
      },
      {
        name: "Grillo blanc",
        price: "5.5€ verre / 25€ bouteille",
      },
      {
        name: "Lambrusco rouge",
        price: "5€ verre / 20€ bouteille",
      },
      {
        name: "Nero d'Avola rouge",
        price: "5€ verre / 20€ bouteille",
      },
      {
        name: "San Giovese rouge",
        price: "5€ verre / 20€ bouteille",
      },
      {
        name: "Lamvrusco rosé",
        price: "5€ verre / 20€ bouteille",
      },
      {
        name: "Pistamutta rosé",
        price: "5.5€ verre / 25€ bouteille",
      },
      {
        name: "Spritz",
        price: "6€",
      },
      {
        name: "Kir cassis",
        price: "7€",
      },
      {
        name: "Martini rouge ou blanc",
        price: "7€",
      },
      {
        name: "Bière Moretti",
        price: "5.5€",
      },
      {
        name: "Bière Peroni Nastro Azzurro",
        price: "5.5€",
      },
      {
        name: "Limoncello",
        price: "4.5€",
      },
    ],
  },
  {
    title: MenuSectionEnum.MENU_MIDI,
    component: <MenuMidi />,
  },
];
