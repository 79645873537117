import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { Parallax } from "react-parallax";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageDiv: {
      height: "800px",
      [theme.breakpoints.down("xs")]: {
        height: "600px",
      },
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      marginTop: theme.spacing(4),
    },
    mapContainer: {
      width: "800px",
      height: "550px",
      [theme.breakpoints.down("xs")]: {
        width: "400px",
        height: "300px",
      },
    },
    title: {
      fontSize: 60,
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
    adress: {
      fontSize: 28,
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
      },
      color: theme.palette.primary.light,
      marginBottom: theme.spacing(3),
      textAlign: "center",
    },
  })
);

export const Adress = () => {
  const classes = useStyles();
  return (
    <div id="adress" className={classes.container}>
      <Parallax bgImage={"sea.webp"} strength={-200}>
        <div className={classes.imageDiv}>
          <Typography className={classes.title}>Notre adresse</Typography>
          <Typography className={classes.adress}>
            13 Avenue du Général Leclerc 77330 Ozoir-la-Ferrière France
          </Typography>
          <MapContainer
            center={[48.764951751166286, 2.672774389913182]}
            zoom={15}
            className={classes.mapContainer}
            attributionControl={false}
            scrollWheelZoom={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[48.764951751166286, 2.672774389913182]}></Marker>
          </MapContainer>
        </div>
      </Parallax>
    </div>
  );
};
