import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 36,
      [theme.breakpoints.down("xs")]: {
        fontSize: "26px",
      },
      color: theme.palette.primary.light,
      textAlign: "center",
      width: "100%",
    },
    menuContainer: {
      flex: 1,
      padding: 8,
      border: `2px solid ${theme.palette.primary.light}`,
      "& *": {
        textAlign: "center",
      },
    },
    menuSection: {
      fontSize: 36,
      [theme.breakpoints.down("xs")]: {
        fontSize: "26px",
      },
      color: theme.palette.primary.light,
      textAlign: "center",
      width: "100%",
    },
    menuChoices: {
      fontSize: 26,
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
      color: "white",
      textAlign: "center",
      width: "100%",
    },
  })
);

export const MenuMidi = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box display={"flex"} flex={1} paddingTop={1}>
      <Box className={classes.menuContainer}>
        <Typography className={classes.title}>Menu A - 14.5€</Typography>
        <Typography className={classes.menuSection}>Entrées</Typography>
        <Typography className={classes.menuChoices}>
          burratina ou mini arancini
        </Typography>
        <Typography className={classes.menuSection}>Plats</Typography>
        <Typography className={classes.menuChoices}>
          pizza margherita ou pesto
        </Typography>
        <Typography className={classes.menuChoices}>
          <b>OU</b>
        </Typography>
        <Typography className={classes.menuChoices}>
          pasta al pomodoro ou aglio e olio
        </Typography>
      </Box>
      <Box className={classes.menuContainer}>
        <Typography className={classes.title}>Menu B - 17€</Typography>
        <Typography className={classes.menuSection}>Entrées</Typography>
        <Typography className={classes.menuChoices}>
          burratina ou mini arancini
        </Typography>
        <Typography className={classes.menuSection}>Plats</Typography>
        <Typography className={classes.menuChoices}>
          pizza regina ou 4 formaggi
        </Typography>
        <Typography className={classes.menuChoices}>
          <b>OU</b>
        </Typography>
        <Typography className={classes.menuChoices}>
          pasta al ragu ou boscaiola ou carbonara
        </Typography>
      </Box>
    </Box>
  );
};
