import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createStyles,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MENU_CONTENT } from "./constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      backgroundColor: "inherit",
    },
    firstAccordion: {
      backgroundColor: "inherit",
      "&:before": { height: "0px" },
    },
    title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: theme.spacing(2),
      fontSize: 60,
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
      color: "#f2ede9",
    },
    itemName: {
      fontSize: 36,
      [theme.breakpoints.down("xs")]: {
        fontSize: "26px",
      },
      color: theme.palette.primary.light,
      textAlign: "center",
      width: "100%",
    },
    itemIngredients: {
      fontSize: 26,
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
      color: "white",
      textAlign: "center",
      width: "100%",
    },
    listContainer: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    expand: {
      color: "#f2ede9",
    },
    accordionSummary: {
      "& div": {
        justifyContent: "center",
      },
    },
    accordionDetails: {
      backgroundColor: "#6e2f2f",
      justifyContent: "center",
    },
    sectionTitle: {
      fontSize: 32,
      [theme.breakpoints.down("xs")]: {
        fontSize: "28px",
      },
    },
  })
);

export const MyMenu = () => {
  const menuContent = MENU_CONTENT;
  const classes = useStyles();

  return (
    <Box id="mymenu">
      <Typography className={classes.title}>La Carte</Typography>
      {menuContent.map((menuSection, i) => {
        return (
          <Accordion
            className={i === 0 ? classes.firstAccordion : classes.accordion}
            elevation={0}
          >
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={<ExpandMoreIcon className={classes.expand} />}
              id={menuSection.title}
            >
              <Typography className={classes.sectionTitle}>
                {menuSection.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {menuSection.items && (
                <List>
                  {menuSection.items.map((item, itemIndex) => {
                    return (
                      <ListItem key={`${itemIndex}`} disableGutters={true}>
                        {
                          <div className={classes.listContainer}>
                            <Typography
                              className={classes.itemName}
                            >{`${item.name} - ${item.price}`}</Typography>
                            {item.ingredients && (
                              <Typography className={classes.itemIngredients}>
                                {item.ingredients}
                              </Typography>
                            )}
                          </div>
                        }
                      </ListItem>
                    );
                  })}
                </List>
              )}
              {menuSection.component}
            </AccordionDetails>
          </Accordion>
        );
      })}
      {/* <MenuMidi /> */}
    </Box>
  );
};
