import {
  AppBar as MuiAppBar,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      height: "70px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2),
      boxShadow: `0px 0.5px 0px ${theme.palette.primary.light}`,
    },
    text: {
      fontSize: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
    links: {
      marginLeft: "auto",
      [theme.breakpoints.up("xs")]: {
        maxWidth: "360px",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "190px",
      },
    },
    button: {
      marginRight: theme.spacing(3),
      width: "95px",
      fontSize: "24px",
    },
    buttonText: {
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  })
);

export const Appbar = () => {
  const classes = useStyles();

  const handleClick = (id: string) => {
    const ref = document.getElementById(id);
    if (ref) {
      window.scroll({ top: ref.offsetTop - 70, behavior: "smooth" });
    }
  };

  return (
    <MuiAppBar position="sticky" className={classes.appbar}>
      <Typography className={classes.text}>Ma Che!</Typography>
      <Grid container className={classes.links}>
        <Grid item sm={3} xs={6}>
          <Button
            onClick={() => {
              handleClick("about");
            }}
            className={classes.button}
          >
            <Typography className={classes.buttonText}>A propos</Typography>
          </Button>{" "}
        </Grid>
        <Grid item sm={3} xs={6}>
          <Button
            onClick={() => {
              handleClick("mymenu");
            }}
            className={classes.button}
          >
            <Typography className={classes.buttonText}>La Carte</Typography>
          </Button>{" "}
        </Grid>
        <Grid item sm={3} xs={6}>
          <Button
            onClick={() => {
              handleClick("adress");
            }}
            className={classes.button}
          >
            <Typography className={classes.buttonText}>Adresse</Typography>
          </Button>{" "}
        </Grid>
        <Grid item sm={3} xs={6}>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
            className={classes.button}
          >
            <Typography className={classes.buttonText}>Contact</Typography>
          </Button>{" "}
        </Grid>
      </Grid>
    </MuiAppBar>
  );
};
