import { AboutUs } from "../../components/AboutUs";
import { Adress } from "../../components/Adress";
import { Appbar } from "../../components/AppBar";
import { Contact } from "../../components/Contact";
import { InfoBar } from "../../components/InfoBar";
import { MyMenu } from "../../components/MyMenu";

export const Page = () => {
  return (
    <div>
      <Appbar />
      <InfoBar />
      <AboutUs />
      <MyMenu />
      <Adress />
      <Contact />
    </div>
  );
};
