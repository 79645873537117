import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
    palette: {
        primary: {
            light: "#d6cac1",
            main: "#5c2222",
            dark: "#471a1a"
        }
    },
    typography: {
        fontFamily: [
            "Times New Roman"
        ].join(','),
        allVariants: {
            color: "#f2ede9"
        }
    }
  });