import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Parallax } from "react-parallax";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageDiv: {
      height: "500px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    textTitle: {
      color: theme.palette.common.white,
      fontSize: 60,
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
      marginBottom: theme.spacing(5),
    },
    textContent: {
      color: theme.palette.common.white,
      opacity: 1,
      fontSize: 28,
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
      },
      marginTop: theme.spacing(2),
      maxWidth: "70%",
      textAlign: "center",
    },
    number: {
      color: theme.palette.primary.light,
    },
  })
);

export const Contact = () => {
  const classes = useStyles();
  return (
    <div id="contact">
      <Parallax bgImage={"farine.webp"} strength={-200}>
        <div className={classes.imageDiv}>
          <Typography className={classes.textTitle}>Contact</Typography>
          <Typography className={classes.textContent}>
            Contactez-nous au
            {<span className={classes.number}> 06 09 81 19 92</span>} pour plus
            d'information, ou pour nous communiquer votre commande!
          </Typography>
        </div>
      </Parallax>
    </div>
  );
};
