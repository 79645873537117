import { ThemeProvider } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import { theme } from "./components/Theme";
import { Page } from "./pages/Page";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Page />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
